.App {
  max-width: 1024px;
  margin: auto;
  text-align: center;
}

.apps-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;

  a {
    text-decoration: none;
  }
}

.app-card {
  margin: 1rem;
  padding: 1rem;
  border-radius: 10%;
  text-align: center;

  &:hover {
    background-color: #eee;
  }

  img {
    width: 256px;
    border-radius: 50%;
    padding-bottom: 0.25rem;
  }

  .app-name {
    display: block;
    font-size: 1.3rem;
    color: black;
    text-decoration: none;
  }
}
